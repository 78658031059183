import styled from 'styled-components'

export const TableDetailLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`
