import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const SortText = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  margin-right: 0.5rem;
`

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0.25rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.architecture3};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
  }
`

export const AlphabeticalIndicator = styled.span<{ color: 'black' | 'typeTertiary' }>`
  color: ${({ color, theme }) =>
    color === 'black' ? theme.colors.black : theme.colors.typeTertiary};
  white-space: nowrap;
`
