import { Pagination } from 'antd'
import styled from 'styled-components'
import { Theme } from '../../themes'

export const paginationStyles = (theme: Theme) => `
  display: flex;
  justify-content: center;
  margin: 3.75rem 0 0 0;

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-item,
  .ant-pagination-jump-next,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    height: 2.5rem;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 1.25rem;
      border: 0.0625rem solid ${theme.colors.architecture5};
      background-color: transparent;
      color: ${theme.colors.typeTertiary};
    }

    .ant-pagination-item-link[disabled] {
      opacity: ${theme.opacity.disabled};
    }
  }

  .ant-pagination-prev {
    margin-right: 2.5rem;
  }

  .ant-pagination-item {
    margin-right: 1.25rem;
    border-radius: 1.5rem;

    background-color: transparent;
    border: 0.0625rem solid ${theme.colors.architecture5};
    font-size: 0.875rem;
    font-weight: 700;

    a {
      color: ${theme.colors.typeTertiary};
    }
  }

  .ant-pagination-item-active {
    background-color: ${theme.colors.architecture5};

    a {
      color: ${theme.colors.typeDarkPrimary};
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 1.25rem;

    a {
      width: 100%;
    }

    .ant-pagination-item-ellipsis {
      color: ${theme.colors.typeTertiary};
    }
  }

  .ant-pagination-next {
    margin-left: 1.25rem;
  }
`

export const StyledPagination = styled(Pagination)`
  ${({ theme }) => paginationStyles(theme)}
`
