import { Table } from 'antd'
import styled from 'styled-components'
import { paginationStyles } from '../Pagination'

export const StyledTable = styled(Table)<{
  $noBackgoundColorOnHover?: boolean
  $withBottomPadding?: boolean
  $withTotalRow?: boolean
  $alignItemsTop?: boolean
}>`
  .border-right {
    border-right: 1px solid ${({ theme }) => theme.colors.architecture3} !important;
  }

  ${({ $alignItemsTop }) =>
    $alignItemsTop
      ? `td {
    vertical-align: top;
  }`
      : ''};

  // there is an invisible row "ant-table-measure-row" as a first child,
  // that's why we need to select the second child
  .ant-table-tbody > tr:nth-child(2) > td {
    ${({ $withTotalRow, theme }) =>
      $withTotalRow
        ? ` border-bottom: 1px solid ${theme.colors.architecture3} !important;
            text-transform: uppercase;
            font-weight: bold;
          `
        : ''}
  }

  div.ant-table {
    background: ${({ theme }) => theme.colors.bgSecondary};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    border: 1px solid ${({ theme }) => theme.colors.architecture3};
    ${({ $withBottomPadding = true }) => ($withBottomPadding ? 'padding-bottom: 0.5rem' : '')}
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.typeSecondary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
    line-height: normal;

    .ant-table-column-sorters {
      padding: 0;
    }

    &.no-wrap-header {
      white-space: nowrap;
    }
  }

  .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead {
    tr {
      &:first-child > th,
      &:first-child > td {
        background: ${({ theme }) => theme.colors.white};
        border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
      }
      &:last-child > th,
      &:last-child > td {
        background: ${({ theme }) => theme.colors.bgPrimary};
      }
    }
  }

  .ant-table-thead > tr > th {
    height: ${({ theme }) => theme.sizing.tableHeaderRowHeight};
    padding: 0 1.25rem;
  }

  .ant-table-tbody > tr > td {
    height: ${({ theme }) => theme.sizing.tableRowHeight};
    padding: ${({ $alignItemsTop }) => ($alignItemsTop ? '0.5rem 1.25rem;' : '0 1.25rem;')};
  }

  /* TODO: we may actually want to apply this globally at some point. */
  .ant-table-tbody > tr.reduceRowHeight > td {
    height: 2.5rem;
  }

  .ant-table-tbody > tr.reduceFirstColumnPadding > td {
    &:first-child {
      padding-right: 0;
    }
  }

  .ant-table-tbody > tr.reduceLastColumnPadding > td {
    &:last-child {
      padding: 0;
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 0.75rem;
    border: 0;

    color: ${({ theme }) => theme.colors.typePrimary};

    a {
      color: ${({ theme }) => theme.colors.actionLink};
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    /* !important to ensure it overrides any custom background colours. */
    background-color: ${({ theme, $noBackgoundColorOnHover }) =>
      $noBackgoundColorOnHover ? 'transparent' : theme.colors.bgPrimary} !important;
  }

  .ant-table-tbody > tr.ant-table-row > td .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.typeTertiary};
  }

  .ant-table-tbody > tr.ant-table-row > td .ant-checkbox-checked > .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.typePrimary};
  }

  .ant-table-tbody > tr.ant-table-row > td .ant-checkbox-disabled > .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.typeTertiary};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td .ant-checkbox-inner {
    background: ${({ theme }) => theme.colors.white};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td .ant-checkbox-checked > .ant-checkbox-inner {
    background: ${({ theme }) => theme.colors.actionPrimary};
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: ${({ theme }) => theme.colors.bgPrimary};
  }

  .ant-table-tbody > tr.ant-table-row.disabledRow > td,
  .ant-table-tbody > tr.ant-table-row.disabledRow > td > div,
  .ant-table-tbody > tr.ant-table-row.disabledRow > td > div > div,
  .ant-table-tbody > tr.ant-table-row.disabledRow > td > div > div > svg,
  .ant-table-tbody > tr.ant-table-row.disabledRow > td > span {
    color: ${({ theme }) => theme.colors.typeTertiary};

    img {
      opacity: 0.1;
    }

    svg {
      color: ${({ theme }) => theme.colors.typeTertiary};
    }

    .standard-badge {
      --active-color: ${({ theme }) => theme.colors.typeTertiary};
    }
    .reported-icon {
      background-color: ${({ theme }) => theme.colors.typeTertiary};
    }

    .follow-table-row-highlight-inverted {
      background-color: ${({ theme }) => theme.colors.bgPrimary};
      color: ${({ theme }) => theme.colors.typeDarkPrimary};
    }
  }

  .ant-table-tbody > tr.ant-table-row.disabledRow:hover > td {
    background: unset;
  }

  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .ant-pagination {
    ${({ theme }) => paginationStyles(theme)}
  }

  /* loading state */
  .ant-spin-blur {
    opacity: 1;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    margin: 2rem -10px;
  }
  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.typeSecondary};
  }

  .highlighted-column {
    /* !important required to apply to override header cell too. */
    background-color: ${({ theme }) => theme.colors.bgPrimary} !important;
  }
  .ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
    display: none;
  }
` as typeof Table

export const LoadingContainer = styled.div`
  padding: 3rem 0 6rem;
`
