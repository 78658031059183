import { FC } from 'react'

const CaretUpIcon: FC<{ color: string }> = ({ color }) => (
  <path
    d="M11.2964 17.3L8.69635 14.7C8.37968 14.3833 8.30902 14.021 8.48435 13.613C8.65968 13.205 8.97202 13.0007 9.42135 13H14.5713C15.0213 13 15.334 13.2043 15.5093 13.613C15.6847 14.0217 15.6137 14.384 15.2964 14.7L12.6963 17.3C12.5963 17.4 12.488 17.475 12.3714 17.525C12.2547 17.575 12.1297 17.6 11.9964 17.6C11.863 17.6 11.738 17.575 11.6214 17.525C11.5047 17.475 11.3964 17.4 11.2964 17.3Z"
    fill={color}
  />
)

export default CaretUpIcon
