import { FC } from 'react'
import { Icon } from '../Icon'
import { TableDetailLinkContainer } from './TableDetailButton.style'

const TableDetailButton: FC = () => (
  <TableDetailLinkContainer>
    <Icon color="black" icon="ChevronRight" alt="row detail" />
  </TableDetailLinkContainer>
)

export default TableDetailButton
