import styled from 'styled-components'

export const LinkText = styled.div`
  color: ${({ theme }) => theme.colors.actionLink};
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
