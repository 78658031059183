import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import { isDevEnv, logger } from '@netpurpose/utils'

const ERRORS_TO_IGNORE_IN_LOCAL_DEV = [
  // This error is persistent when running the app locally, but doesn't seem to
  // be a significant issue in production, so I think we can safely ignore it.
  'signal is aborted without reason',
]

export const useUnleashFlag = (flagName: string): boolean => {
  const { flagsError } = useFlagsStatus()
  const flagEnabled = useFlag(flagName)

  // N.B. flagsError isn't particularly encompassing. It will return an error if
  // the request fails entirely, but if it gets a 404 after previously receiving
  // a successful response, it comes back as null and flagsReady as true.
  if (flagsError) {
    if (!isDevEnv() || !ERRORS_TO_IGNORE_IN_LOCAL_DEV.includes((flagsError as Error).message)) {
      logger.error({ err: flagsError })
    }
    // Assume that if Unleash is having issues then all feature toggles should be off by default.
    return false
  }

  return flagEnabled
}
