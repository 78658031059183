import { FC } from 'react'

const CaretDownIcon: FC<{ color: string }> = ({ color }) => (
  <path
    d="M12.7036 6.7L15.3037 9.3C15.6203 9.61667 15.691 9.979 15.5157 10.387C15.3403 10.795 15.028 10.9993 14.5786 11H9.42865C8.97865 11 8.66598 10.7957 8.49065 10.387C8.31532 9.97833 8.38632 9.616 8.70365 9.3L11.3037 6.7C11.4037 6.6 11.512 6.525 11.6286 6.475C11.7453 6.425 11.8703 6.4 12.0036 6.4C12.137 6.4 12.262 6.425 12.3786 6.475C12.4953 6.525 12.6036 6.6 12.7036 6.7Z"
    fill={color}
  />
)

export default CaretDownIcon
