import { FC, ReactElement } from 'react'
import { FilterConfig, SortDirection } from '@netpurpose/types'
import { AlphabeticalIndicator, ArrowContainer, Container, SortText } from './SortControl.style'
import { SortIcon } from './SortIcon'

const SortIndicator: FC<{
  direction: SortDirection
  activeDirection: SortDirection | undefined
  getHandleClick: (direction: SortDirection) => () => void
}> = ({ direction, activeDirection, getHandleClick }) => {
  const color = direction === activeDirection ? 'black' : 'typeTertiary'
  const alphabeticalText = direction === SortDirection.ASC ? 'A-Z' : 'Z-A'

  return (
    <ArrowContainer onClick={getHandleClick(direction)}>
      <AlphabeticalIndicator color={color}>{alphabeticalText}</AlphabeticalIndicator>
    </ArrowContainer>
  )
}

type Props<Model> = {
  dataIndex: keyof Model
  filterConfig: FilterConfig<Model>
  showSortText?: boolean
  sortIsAlphabetical: boolean
}

export const SortControl = <Model,>({
  dataIndex,
  filterConfig,
  showSortText = true,
  sortIsAlphabetical,
}: Props<Model>): ReactElement => {
  const { sorting, setSortDirection } = filterConfig
  const activeDirection = sorting[dataIndex]

  const getHandleClick = (direction: SortDirection) => () => {
    if (activeDirection === direction) {
      return setSortDirection(dataIndex, null)
    }
    return setSortDirection(dataIndex, direction)
  }

  return (
    <Container>
      {showSortText && <SortText>Sort</SortText>}
      {sortIsAlphabetical ? (
        <>
          <SortIndicator
            direction={SortDirection.ASC}
            activeDirection={activeDirection}
            getHandleClick={getHandleClick}
          />
          <SortIndicator
            direction={SortDirection.DESC}
            activeDirection={activeDirection}
            getHandleClick={getHandleClick}
          />
        </>
      ) : (
        <SortIcon dataIndex={dataIndex} filterConfig={filterConfig} />
      )}
    </Container>
  )
}
